import { Scene, GameObjects } from 'phaser';

import { assetHelper } from '../helpers/assetHelper';
import { maskHelper } from '../helpers/maskHelper';

import { SimpleButton } from '../gameobjects/SimpleButton';

export class MainMenu extends Scene {
    
    camera: Phaser.Cameras.Scene2D.Camera;

    background: GameObjects.Image;
    maskRect: Phaser.Geom.Rectangle;
    maskGraphics: GameObjects.Graphics;
    backgroundMask: Phaser.Display.Masks.GeometryMask;

    overlay: GameObjects.Graphics;

    mainLogo: GameObjects.Image;
    playButton: SimpleButton;

    constructor () {
        super('MainMenu');
    }

    init() {

        this.camera = this.cameras.main;
        this.camera.setBackgroundColor(0x000000);
        
    }

    create() {

        // background and mask

        this.maskRect = Phaser.Geom.Rectangle.Clone(maskHelper.maskFullRect);
        this.maskGraphics = new GameObjects.Graphics(this);
        maskHelper.redrawMask(this.maskGraphics, this.maskRect);
        this.backgroundMask = new Phaser.Display.Masks.GeometryMask(this, this.maskGraphics);

        this.background = this.add.image(672, 384, assetHelper.texture, 'Background.png');
        this.background.mask = this.backgroundMask;

        // overlay

        this.overlay = new GameObjects.Graphics(this);
        this.overlay.fillStyle(0x000000, 0.5);
        this.overlay.fillRect(maskHelper.maskFullRect.x, maskHelper.maskFullRect.y, maskHelper.maskFullRect.width, maskHelper.maskFullRect.height);
        this.add.existing(this.overlay);
        
        // menu
        
        this.mainLogo = this.add.image(this.camera.centerX, this.camera.centerY - 80, 'MainLogo');

        this.playButton = new SimpleButton(this, 672, 384 + 250, 'PlayButton', () => this.handlePlayButtonClicked());
        this.add.existing(this.playButton);
        
    }

    handlePlayButtonClicked(): void {

        this.mainLogo.visible = false;
        this.playButton.visible = false;

        const transitionDuration: number = 800;
        const transitionDelay: number = 200;

        this.tweens.add({
            targets: this.overlay,
            alpha: 0,
            ease: Phaser.Math.Easing.Quadratic.Out,
            duration: transitionDuration,
            delay: transitionDelay
        });

        this.tweens.add({
            targets: this.maskRect,
            x: { from: maskHelper.maskFullRect.x, to: maskHelper.maskActiveRect.x },
            y: { from: maskHelper.maskFullRect.y, to: maskHelper.maskActiveRect.y },
            width: { from: maskHelper.maskFullRect.width, to: maskHelper.maskActiveRect.width },
            height: { from: maskHelper.maskFullRect.height, to: maskHelper.maskActiveRect.height },
            ease: Phaser.Math.Easing.Quadratic.Out,
            duration: transitionDuration,
            delay: transitionDelay,
            onUpdate: () => this.handleTransitionUpdate(),
            onComplete: () => this.handleTransitionComplete()
        });

    }

    handleTransitionUpdate(): void {

        maskHelper.redrawMask(this.maskGraphics, this.maskRect);

    }

    handleTransitionComplete(): void {

        this.scene.start('TextingGame', { scenario: 'scenario_1' });

    }

}
