import { Scene } from 'phaser';

export class Boot extends Scene {
    
    constructor () {
        super('Boot');
    }

    preload() {

        // load any assets needed for the Preloader
        this.load.image('MainLogo', 'assets/MainLogo.png');

    }

    create() {

        this.scene.start('Preloader');
        
    }

}
