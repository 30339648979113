import { Scene } from 'phaser';

import { assetHelper } from '../helpers/assetHelper';
import { maskHelper } from '../helpers/maskHelper';

export class Preloader extends Scene {
    
    camera: Phaser.Cameras.Scene2D.Camera;

    constructor () {
        super('Preloader');
    }

    init() {

        this.camera = this.cameras.main;
        this.camera.setBackgroundColor(0x000000);

        this.add.image(this.camera.centerX, this.camera.centerY - 80, 'MainLogo');
        
        const loadingBarWidth: number = 200;
        const loadingBarHeight: number = 16;
        const strokeWidth: number = 2;
        const initialBarWidth: number = 4;
        this.add.rectangle(
            this.camera.centerX - strokeWidth,
            this.camera.centerY + 200,
            loadingBarWidth + strokeWidth * 2,
            loadingBarHeight + strokeWidth * 2)
            .setStrokeStyle(strokeWidth, 0xffffff, 0.75);
        const bar = this.add.rectangle(
            this.camera.centerX - loadingBarWidth / 2,
            this.camera.centerY + 200,
            initialBarWidth,
            loadingBarHeight,
            0xffffff, 0.75);
        this.load.on('progress', (progress: number) => {
            bar.width = initialBarWidth + ((loadingBarWidth - initialBarWidth) * progress);
        });
        
    }

    preload() {

        this.load.setPath('assets');

        // load the multi-atlas
        this.load.multiatlas('sprites', 'Sprites.json');

        // load the data
        this.load.json('questionData', 'QuestionData.json');

        // load the music
        this.load.audio('BackgroundMusic', ['BackgroundMusic.mp3', 'BackgroundMusic.ogg']);

    }

    create() {

        assetHelper.createAnimations(this);

        maskHelper.init(this.sys.game.canvas);

        this.scene.start('MainMenu');
        
    }

}
