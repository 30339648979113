import { Scene, GameObjects } from 'phaser';

import { ChoiceButton } from './ChoiceButton';

export class QuestionDisplay extends GameObjects.Container {

    public questionText: GameObjects.Text;
    public questionTextShadow: GameObjects.Text;

    public choices: string[];
    public yPositions: number[];
    public choiceButtons: ChoiceButton[];

    constructor(scene: Scene,
                x: number,
                y: number,
                callback: (choice: string) => void) {

        super(scene, x, y);
        
        this.questionText = new GameObjects.Text(
            scene, 20, 20,
            '',
            {
                fontFamily: 'Bahnschrift, Arial, sans-serif',
                fontSize: 42,
                lineSpacing: 3,
                color: '#fff',
                align: 'left'
            }
        ).setOrigin(0, 0);
        this.questionText.setWordWrapWidth(762);

        this.questionTextShadow = new GameObjects.Text(
            scene, 23, 23,
            '',
            {
                fontFamily: 'Bahnschrift, Arial, sans-serif',
                fontSize: 42,
                lineSpacing: 3,
                color: '#000',
                align: 'left'
            }
        ).setOrigin(0, 0);
        this.questionTextShadow.setWordWrapWidth(762);
        
        this.choices = ['a', 'b', 'c', 'd'];
        this.yPositions = [];
        this.choiceButtons = [];
        let yPos = 384;
        for (let i = 0; i < this.choices.length; i++) {

            this.yPositions.push(yPos);

            const button = new ChoiceButton(scene, 10, yPos, '', this.choices[i], callback);
            this.choiceButtons.push(button);
            button.visible = false;

            yPos += 70;
        }

        this.add(this.questionTextShadow);
        this.add(this.questionText);
        this.choiceButtons.forEach((choice) => {
            this.add(choice);
        });

    }

    public setQuestion(text: string, choices: any) {
        
        this.questionText.setFontSize(42);
        this.questionTextShadow.setFontSize(42);
        this.questionText.text = this.questionTextShadow.text = text;
        this.questionText.alpha = this.questionTextShadow.alpha = 0;

        let isOverflow = this.questionText.getBounds().height > 230;
        if (isOverflow) {
            this.questionText.setFontSize(36);
            this.questionTextShadow.setFontSize(36);
        }

        for (let i = 0; i < this.choiceButtons.length; i++) {
            this.choiceButtons[i].y = this.yPositions[i];
            this.choiceButtons[i].visible = false;
            this.choiceButtons[i].alpha = 0;
        }
        if (choices.hasOwnProperty('a')) {
            this.choiceButtons[0].setText(choices['a']);
            this.choiceButtons[0].visible = true;
        }
        if (choices.hasOwnProperty('b')) {
            this.choiceButtons[1].setText(choices['b']);
            this.choiceButtons[1].visible = true;
        }
        if (choices.hasOwnProperty('c')) {
            this.choiceButtons[2].setText(choices['c']);
            this.choiceButtons[2].visible = true;
        }
        if (choices.hasOwnProperty('d')) {
            this.choiceButtons[3].setText(choices['d']);
            this.choiceButtons[3].visible = true;
        }

    }

    public enableButtons(isEnabled: boolean): void {

        this.choiceButtons.forEach((button) => {
            button.enableButton(isEnabled);
        });

    }

    public fadeIn(scene: Scene): void {

        scene.tweens.add({
            targets: [this.questionText, this.questionTextShadow],
            alpha: 1,
            ease: Phaser.Math.Easing.Quadratic.Out,
            duration: 1000
        });

        this.choiceButtons.forEach((button) => {
            button.resetButton();
            scene.tweens.add({
                targets: button,
                alpha: 1,
                ease: Phaser.Math.Easing.Quadratic.Out,
                duration: 800,
                delay: 1200,
                onComplete: () => this.enableButtons(true)
            });
        });

    }

    public fadeOutQuestionText(scene: Scene): void {

        scene.tweens.add({
            targets: [this.questionText, this.questionTextShadow],
            alpha: 0,
            ease: Phaser.Math.Easing.Quadratic.Out,
            duration: 300
        });

    }
    
    public fadeOutButtons(scene: Scene, exceptions: Array<string>): void {

        this.choiceButtons.forEach((button) => {
            if (!exceptions.includes(button.choice)) {
                scene.tweens.add({
                    targets: button,
                    alpha: 0,
                    ease: Phaser.Math.Easing.Quadratic.Out,
                    duration: 300
                });
            }
        });

    }

    public animateCorrect(scene: Scene, correctChoice: string, choice: string): void {

        const buttons: ChoiceButton[] = [];
        this.choiceButtons.forEach((button) => {
            if (button.choice == correctChoice) {
                buttons.push(button);
            }
        });

        scene.tweens.add({
            targets: buttons,
            y: this.yPositions[0] - 140,
            ease: Phaser.Math.Easing.Quadratic.InOut,
            duration: 800
        });

        if (choice != correctChoice) {
            /*
            buttons.forEach((button) => {
                button.fadeInCorrectMark(scene);
            });
            */
        }

    }
    /*
    public fadeOutCorrectMark(scene: Scene, correctChoice: string): void {

        const buttons: ChoiceButton[] = [];
        this.choiceButtons.forEach((button) => {
            if (button.choice == correctChoice) {
                buttons.push(button);
            }
        });

        buttons.forEach((button) => {
            button.fadeOutCorrectMark(scene);
        });

    }
    */
}
